// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../const';

export const followUpAddCollection = (addFollowUp: JSON) => {
	const data = {
		addFollowUp: addFollowUp,
	};
	const url = `${API_URL}addCollectionFollowUp`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const followUpFetchCollection = (fetchFollowUp: JSON) => {
	const data = {
		FetchFollowUpList: fetchFollowUp,
	};
	const url = `${API_URL}fetchCollectionFollowUp`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchLoanDetails = (loanData: JSON) => {
	const data = {
		LoanDetailFetch: loanData,
	};
	const url = `${API_URL}fetchApplicantLoanDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchInfoDetails = (loginData: JSON) => {
	const data = {
		LoanLonaInfoFetch: loginData,
	};
	const url = `${API_URL}fetchLoanScheduleInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
