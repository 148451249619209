import loginActionTypes from './login.types';
import { decodeApiResponse } from '../_common/common.utils';

const INITIAL_STATE: any = {
	loginResponse: null,
	authCheckDone: 0,
	loginDeocodedResponse: [],
	userAuthVerifyResponse: null,
	currencySymbols: '',
};

const loginReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case loginActionTypes.USER_SIGN_IN_SUCCESS:
			return {
				...state,
				loginResponse: action.payload,
				loginDeocodedResponse: decodeApiResponse(
					action.payload.response,
					'UserKeyLogin',
				),
			};

		case loginActionTypes.USER_SIGN_IN_FAILURE:
			return {
				...state,
				loginResponse: action.payload,
				loginDeocodedResponse: decodeApiResponse(
					action.payload.response,
					'UserKeyLogin',
				),
			};

		case loginActionTypes.USER_SIGN_IN_RESPONSE_CHANGED:
			return {
				...state,
				loginResponse: null,
			};

		case loginActionTypes.USER_SIGN_OUT:
			return {
				...state,
				authCheckDone: 0,
				loginResponse: null,
				loginDeocodedResponse: [],
			};

		case loginActionTypes.USER_AUTH_CHECK_DONE_CHANGED:
			return {
				...state,
				authCheckDone: action.payload
			};

		case loginActionTypes.USER_AUTH_VERIFY_SUCCESS:
			return {
				...state,
				userAuthVerifyResponse: action.payload,
			};

		case loginActionTypes.USER_AUTH_VERIFY_FAILURE:
			return {
				...state,
				userAuthVerifyResponse: action.payload,
			};

		case loginActionTypes.USER_AUTH_VERIFY_RESPONSE_CHANGED:
			return {
				...state,
				userAuthVerifyResponse: null,
			};
		default:
			return state;
	}
};

export default loginReducer;
