import { takeLatest, put, all, call } from 'redux-saga/effects';

import collectionActionTypes from './collection.types';
import {
	followUpAddCollection,
	followUpFetchCollection,
	fetchLoanDetails,
	fetchInfoDetails
} from './collection.services';
import {
	collectionFollowUpAddSuccess,
	collectionFollowUpAddFailure,
	collectionFollowUpAddResponseChanged,
	collectionFollowUpFetchSuccess,
	collectionFollowUpFetchFailure,
	collectionFollowUpFetchResponseChanged,
	loanDetailsFetchSuccess,
	loanDetailsFetchFailure,
	loanDetailsFetchResponseChanged,
	loanInfoFetchSuccess,
	loanInfoFetchFailure,
	loanInfoFetchResponseChanged,
} from './collection.action';
import { encodeJson } from '../_common/enode-decode';

// ADD COLLECTION FOLLOW UP
export function* collectionFollowUpAddDetails({ payload }: any) {
	const key: any = 'addCollectionFollowUp';
	try {
		const { 
			followUpNumber,
			followUpReferenceType,
			followUpStatus,
			followUpReason,
			nextFollowUp,
			nextFollowUpTime,
			memberStudentID,
			userID
		} = payload;

		const jsonData: any = {
			memberStudentID: memberStudentID,
			followedNumber: followUpNumber,
			followedNumberFor: followUpReferenceType,
			followUpStatus: followUpStatus,
			followUpReason: followUpReason,
			nextFollowUpDate: nextFollowUp,
			nextFollowUpTime: nextFollowUpTime,
			userID: userID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(followUpAddCollection, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(collectionFollowUpAddFailure(responseData.data));
		} else {
			yield put(collectionFollowUpAddSuccess(responseData.data));
		}
	} catch (error) {
		yield put(collectionFollowUpAddFailure(error));
	}
}

export function* collectionFollowUpAddResponseReset() {
	yield put(collectionFollowUpAddResponseChanged());
}

export function* onCollectionFollowUpAdd() {
	yield takeLatest(collectionActionTypes.ADD_COLLECTION_FOLLOWUP_START, collectionFollowUpAddDetails);
}

export function* onCollectionFollowUpAddResponseReset() {
	yield takeLatest(
		collectionActionTypes.ADD_COLLECTION_FOLLOWUP_RESET_START,
		collectionFollowUpAddResponseReset,
	);
}

// FETCH COLLECTION FOLLOW UP LOG
export function* collectionFollowUpFetchDetails({ payload }: any) {
	const key: any = 'followUpCollectionFetch';
	try {
		const { memberStudentID } = payload;

		const jsonData: any = {
			memberStudentID
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(followUpFetchCollection, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(collectionFollowUpFetchFailure(responseData.data));
		} else {
			yield put(collectionFollowUpFetchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(collectionFollowUpFetchFailure(error));
	}
}

export function* collectionFollowUpFetchResponseReset() {
	yield put(collectionFollowUpFetchResponseChanged());
}

export function* onCollectionFollowUpFetch() {
	yield takeLatest(collectionActionTypes.FETCH_COLLECTION_FOLLOWUP_START, collectionFollowUpFetchDetails);
}

export function* onCollectionFollowUpFetchResponseReset() {
	yield takeLatest(
		collectionActionTypes.FETCH_COLLECTION_FOLLOWUP_RESET_START,
		collectionFollowUpFetchResponseReset,
	);
}

// LOAN DETAILS FETCH
export function* loanDetailsFetch({ payload }: any) {
	const key: any = 'loanDetails';
	try {
		const { memberStudentID, loanStatus } = payload;

		const jsonData: any = {
			memberStudentID,
			loanStatus,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(fetchLoanDetails, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(loanDetailsFetchFailure(responseData.data));
		} else {
			yield put(loanDetailsFetchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(loanDetailsFetchFailure(error));
	}
}

export function* loanDetailsResponseReset() {
	yield put(loanDetailsFetchResponseChanged());
}

export function* onLoanDetailsFetch() {
	yield takeLatest(collectionActionTypes.LOAN_DETAILS_FETCH_START, loanDetailsFetch);
}

export function* onLoanDetailsFetchesponseReset() {
	yield takeLatest(collectionActionTypes.LOAN_DETAILS_FETCH_RESET_START, loanDetailsResponseReset);
}

// LOAN INFO DETAILS FETCH

export function* loanInfoFetch({ payload }: any) {
	const key: any = 'loanInfoDetails';
	try {
		const { memberStudentID, loanInfoID } = payload;

		const jsonData: any = {
			memberStudentID,
			loanInfoID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(fetchInfoDetails, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(loanInfoFetchFailure(responseData.data));
		} else {
			yield put(loanInfoFetchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(loanInfoFetchFailure(error));
	}
}

export function* loanInfoResponseReset() {
	yield put(loanInfoFetchResponseChanged());
}

export function* onLoanInfoFetch() {
	yield takeLatest(collectionActionTypes.LOAN_INFO_FETCH_START, loanInfoFetch);
}

export function* onLoanDetailsInfoetchesponseReset() {
	yield takeLatest(collectionActionTypes.LOAN_INFO_FETCH_RESET_START, loanInfoResponseReset);
}

export function* collectionSaga() {
	yield all([
		call(onCollectionFollowUpAdd),
		call(onCollectionFollowUpAddResponseReset),
		call(onCollectionFollowUpFetch),
		call(onCollectionFollowUpFetchResponseReset),
		call(onLoanDetailsFetch),
		call(onLoanDetailsFetchesponseReset),
		call(onLoanInfoFetch),
		call(onLoanDetailsInfoetchesponseReset),
	]);
}
