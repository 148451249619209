// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable eqeqeq */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import operationActionTypes from './operations.types';
import {
	fetchDetailsForApplicants,
	processAppicantsProfile,
	finalProcessApplicant,
	checkHighCheckDetails,
	followUpAddNapa,
	followUpFetchNapa,
} from './operations.services';
import {
	fetchApplicanrDetailsSuccess,
	fetchApplicantDetailsFailure,
	fetchApplicantDetailsResponseChanged,
	processApplicantsProfileSuccess,
	processApplicantsProfileFailure,
	processApplicantsProfiletResponseChanged,
	fetchHighmarkDetailSuccess,
	fetchHighmarkDetailFailure,
	fetchHighmarkDetailResponseChanged,
	napaFollowUpAddSuccess,
	napaFollowUpAddFailure,
	napaFollowUpAddResponseChanged,
	napaFollowUpFetchSuccess,
	napaFollowUpFetchFailure,
	napaFollowUpFetchResponseChanged
} from './operations.action';
import { encodeJson } from '../_common/enode-decode';

/** *********************** SATRT APPLICANT DETAILS FETCH LIST ************************ */
export function* applicantFetchDetails({ payload }: any) {
	const key: any = 'NextFetchData';
	try {
		const { userID, job, jobCode } = payload;

		const jsonData: any = {
			TeamID: userID,
			Job: job,
			JobCode: jobCode,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(fetchDetailsForApplicants, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchApplicantDetailsFailure(responseData.data));
		} else {
			yield put(fetchApplicanrDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchApplicantDetailsFailure(error));
	}
}

export function* applicantFetchDetailsResponseReset() {
	yield put(fetchApplicantDetailsResponseChanged());
}

export function* onApplicantFetchDetails() {
	yield takeLatest(operationActionTypes.FETCH_APPLICANT_DETAILS_START, applicantFetchDetails);
}

export function* onApplicantFetchDetailsResponseReset() {
	yield takeLatest(
		operationActionTypes.FETCH_NAPA_DETAILS_RESET_RESET_START,
		applicantFetchDetailsResponseReset,
	);
}
/** *********************** END APPLICANT DETAILS FETCH LIST ************************ */

/** *********************** SATRT PROCEESS APPLICANTS SHORT ************************ */
export function* processAppicantsDetailsProfile({ payload }: any) {
	try {
		const {
			assignID,
			teamID,
			memberStudentID,
			phoneNumber,
			profileStage,

			allDocumentMatchedReview,
			phoneCallReview,
			basicDetailsReview,
			aadharReview,
			collegeReview,
			employeeIdReview,
			paySlipReview,
			panCardReview,
			dirvingLicenceReview,
			voterIDCardReview,
			passportReview,
			photosAndVideoReview,
			bankDetailsReview,

			allMachedVerify,
			isPhoneCallVerify,
			profileRemarks,
			processNote,
			rejectReason,
			memberOperationStatus,
			limitAmount,
			fatherContactVerify,
			motherContactVerify,
			ref1ContactVerify,
			ref2ContactVerify,
			aadharFrontImageVerify,
			aadharBackImageVerify,
			aadharNumberVerify,
			aadharNameVerify,
			aadharFatherNameVerify,
			aadharDobVerify,
			aadharCityVerify,
			aadharStateVerify,
			aadharPinCodeVerify,
			aadharAddressVerify,
			employeeIdFrontImageVerify,
			employeeIdBackImageVerify,
			employeeIdNumberVerify,
			employeeIdNameVerify,
			employeeDesignationVerify,
			employeeDojVerify,
			nameOrganizationVerify,
			yearOrganizationVerify,
			locationVerify,
			dlFrontImageVerify,
			dlBackImageVerify,
			dlNumberVerify,
			dlExpiryDateVerify,
			voterIdFrontImageVerify,
			voterIdBackImageVerify,
			passportFrontImageVerify,
			passportBackImageVerify,
			passportNumberVerify,
			passportDateIssueVerify,
			passportExpiryDateVerify,
			selfieImageVerify,
			selfieVideoVerify,
			collegeIdFrontImageVerify,
			collegeIdBackImageVerify,
			collegeNameVerify,
			streamVerify,
			selectFromyearVerify,
			selectToyearVerify,
			courseShortFormVerify,
			programCategaryVerify,
			rollNumberVerify,
			collegeIdPhoneNumberVerify,
			parendAndGuardian,
			marksheetOrFeesVerify,
			totalMonthlySalaryVerify,
			esinumberVerify,
			pfDeductionVerify,
			payslipPasswordVerify,
			payslipDocumentVerify,
			paysilpMonthVerify,
			bankStatementVerify,
			pancardImageVerify,
			pancardNumberVerify,
			fatherNumberReview,
			motherNumberReview,
			ref1NumberReview,
			ref2NumberReview,
			userID,
			staffID
		} = payload;

		const jsonData: any = {
			assignID,
			teamID,
			memberStudentID,
			phoneNumber,
			allMachedVerify: allMachedVerify ?? 0,
			isPhoneCallVerify: isPhoneCallVerify ?? 0,
			allDocumentMatchReview: allDocumentMatchedReview,
			isPhoneAttendReview: phoneCallReview,
			profileRemarks,
			processNote,
			rejectReason,
			memberOperationStatus,
			limitAmount,
			basicDetailsReview,
			aadharReview,
			collegeIDReview: collegeReview,
			employeeIDReview: employeeIdReview,
			payslipReview: paySlipReview,
			panReview: panCardReview,
			drirvingLicenceReview: dirvingLicenceReview,
			voterIdReview: voterIDCardReview,
			passportReview,
			photoVideoReview: photosAndVideoReview,
			bankDetailsReview,
			fatherContactVerify: fatherContactVerify ?? 0,
			motherContactVerify: motherContactVerify ?? 0,
			ref1ContactVerify: ref1ContactVerify ?? 0,
			ref2ContactVerify: ref2ContactVerify ?? 0,
			aadharFrontImageVerify: aadharFrontImageVerify ?? 0,
			aadharBackImageVerify: aadharBackImageVerify ?? 0,
			aadharNumberVerify: aadharNumberVerify ?? 0,
			aadharNameVerify: aadharNameVerify ?? 0,
			aadharFatherNameVerify: aadharFatherNameVerify ?? 0,
			aadharDobVerify: aadharDobVerify ?? 0,
			aadharCityVerify: aadharCityVerify ?? 0,
			aadharStateVerify: aadharStateVerify ?? 0,
			aadharPinCodeVerify: aadharPinCodeVerify ?? 0,
			aadharAddressVerify: aadharAddressVerify ?? 0,
			employeeIdFrontImageVerify: employeeIdFrontImageVerify ?? 0,
			employeeIdBackImageVerify: employeeIdBackImageVerify ?? 0,
			employeeIdNumberVerify: employeeIdNumberVerify ?? 0,
			employeeIdNameVerify: employeeIdNameVerify ?? 0,
			employeeDesignationVerify: employeeDesignationVerify ?? 0,
			employeeDojVerify: employeeDojVerify ?? 0,
			nameOrganizationVerify: nameOrganizationVerify ?? 0,
			yearOrganizationVerify: yearOrganizationVerify ?? 0,
			locationVerify: locationVerify ?? 0,
			dlFrontImageVerify: dlFrontImageVerify ?? 0,
			dlBackImageVerify: dlBackImageVerify ?? 0,
			dlNumberVerify: dlNumberVerify ?? 0,
			dlExpiryDateVerify: dlExpiryDateVerify ?? 0,
			voterIdFrontImageVerify: voterIdFrontImageVerify ?? 0,
			voterIdBackImageVerify: voterIdBackImageVerify ?? 0,
			passportFrontImageVerify: passportFrontImageVerify ?? 0,
			passportBackImageVerify: passportBackImageVerify ?? 0,
			passportNumberVerify: passportNumberVerify ?? 0,
			passportDateIssueVerify: passportDateIssueVerify ?? 0,
			passportExpiryDateVerify: passportExpiryDateVerify ?? 0,
			selfieImageVerify: selfieImageVerify ?? 0,
			selfieVideoVerify: selfieVideoVerify ?? 0,
			collegeIdFrontImageVerify: collegeIdFrontImageVerify ?? 0,
			collegeIdBackImageVerify: collegeIdBackImageVerify ?? 0,
			collegeNameVerify: collegeNameVerify ?? 0,
			streamVerify: streamVerify ?? 0,
			fromYearVerify: selectFromyearVerify ?? 0,
			toYearVerify: selectToyearVerify ?? 0,
			courseShortFormVerify: courseShortFormVerify ?? 0,
			programCategaryVerify: programCategaryVerify ?? 0,
			rollNumberVerify: rollNumberVerify ?? 0,
			collegeIdPhoneNumberVerify: collegeIdPhoneNumberVerify ?? 0,
			parendAndGuardian: parendAndGuardian ?? 0,
			marksheetOrFeesVerify: marksheetOrFeesVerify ?? 0,
			totalMonthlySalaryVerify: totalMonthlySalaryVerify ?? 0,
			esinumberVerify: esinumberVerify ?? 0,
			pfDeductionVerify: pfDeductionVerify ?? 0,
			payslipPasswordVerify: payslipPasswordVerify ?? 0,
			payslipDocumentVerify: payslipDocumentVerify ?? 0,
			paysilpMonthVerify: paysilpMonthVerify ?? 0,
			bankStatementVerify: bankStatementVerify ?? 0,
			pancardImageVerify: pancardImageVerify ?? 0,
			pancardNumberVerify: pancardNumberVerify ?? 0,
			fatherMobileNoReview: fatherNumberReview,
			motherMobileNoReview: motherNumberReview,
			refOneMobileNoReview: ref1NumberReview,
			refTwoMobileNoReview: ref2NumberReview,
			UserID: userID,
			StaffID: staffID,
		};

		const key: any =
			profileStage == 'AssignL2' ? 'finalProcessApplicants' : 'RefUPdateApplicants';
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(profileStage == 'AssignL2' ? finalProcessApplicant : processAppicantsProfile,
			fetchData,
		);
		if (responseData.data.statusCode === '100') {
			yield put(processApplicantsProfileFailure(responseData.data));
		} else {
			yield put(processApplicantsProfileSuccess(responseData.data));
		}
	} catch (error) {
		yield put(processApplicantsProfileFailure(error));
	}
}

export function* processApplicantsProfileRespReset() {
	yield put(processApplicantsProfiletResponseChanged());
}

export function* onProcessApplicantsProfile() {
	yield takeLatest(
		operationActionTypes.PROCESS_APPLICANTS_PROFILE_START,
		processAppicantsDetailsProfile,
	);
}

export function* onProcessAppicantsProfileRespReset() {
	yield takeLatest(
		operationActionTypes.PROCESS_APPLICANTS_PROFILE_RESET,
		processApplicantsProfileRespReset,
	);
}
/** *********************** END PROCEESS APPLICANTS SHORT ************************ */

/** *********************** HIGHMARK CHECK DETAILS ************************ */
export function* fetchHighMarkCheckDetails({ payload }: any) {
	const key: any = 'HighMark_Check_Details';
	try {
		const { memberID } = payload;

		const jsonData: any = {
			memberStudentID: memberID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(checkHighCheckDetails, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchHighmarkDetailFailure(responseData.data));
		} else {
			yield put(fetchHighmarkDetailSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchHighmarkDetailFailure(error));
	}
}

export function* fetchHighMarkCheckDetailsRespReset() {
	yield put(fetchHighmarkDetailResponseChanged());
}

export function* onFetchHighMarkCheckDetailsStart() {
	yield takeLatest(operationActionTypes.FETCH_HIGHMARK_DETAILS_START, fetchHighMarkCheckDetails);
}

export function* onHighMarkCheckDetailsRespReset() {
	yield takeLatest(
		operationActionTypes.FETCH_HIGHMARK_DETAILS_RESET_START,
		fetchHighMarkCheckDetailsRespReset,
	);
}
/** *********************** HIGHMARK CHECK DETAILS ************************ */

/** *********************** ADD NAPA FOLLOW UP START ************************ */
export function* napaFollowUpAddDetails({ payload }: any) {
	const key: any = 'addNapaFollowUp';
	try {
		const { 
			followUpStatus,
			followUpReason,
			logType,
			nextFollowUp,
			nextFollowUpTime,
			memberStudentID,
			userID
		} = payload;

		const jsonData: any = {
			TeamID: userID,
			AssignType: logType,
			LogFor: followUpStatus,
			MemberID: memberStudentID,
			Note: followUpReason,
			NextFollowUp: nextFollowUp,
			FollowUpTime: nextFollowUpTime,
			UserID: userID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(followUpAddNapa, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(napaFollowUpAddFailure(responseData.data));
		} else {
			yield put(napaFollowUpAddSuccess(responseData.data));
		}
	} catch (error) {
		yield put(napaFollowUpAddFailure(error));
	}
}

export function* napaFollowUpAddResponseReset() {
	yield put(napaFollowUpAddResponseChanged());
}

export function* onNapaFollowUpAdd() {
	yield takeLatest(operationActionTypes.ADD_NAPA_FOLLOWUP_START, napaFollowUpAddDetails);
}

export function* onNapaFollowUpAddResponseReset() {
	yield takeLatest(
		operationActionTypes.ADD_NAPA_FOLLOWUP_RESET_START,
		napaFollowUpAddResponseReset,
	);
}
/** *********************** ADD NAPA FOLLOW UP END ************************ */

/** *********************** FETCH COLLECTION FOLLOW UP LOG START ************************ */
export function* napaFollowUpFetchDetails({ payload }: any) {
	const key: any = 'napaFollowUpFetch';
	try {
		const { memberStudentID } = payload;

		const jsonData: any = {
			memberStudentID
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(followUpFetchNapa, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(napaFollowUpFetchFailure(responseData.data));
		} else {
			yield put(napaFollowUpFetchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(napaFollowUpFetchFailure(error));
	}
}

export function* napaFollowUpFetchResponseReset() {
	yield put(napaFollowUpFetchResponseChanged());
}

export function* onNapaFollowUpFetch() {
	yield takeLatest(operationActionTypes.FETCH_NAPA_FOLLOWUP_START, napaFollowUpFetchDetails);
}

export function* onNapaFollowUpFetchResponseReset() {
	yield takeLatest(
		operationActionTypes.FETCH_NAPA_FOLLOWUP_RESET_START,
		napaFollowUpFetchResponseReset,
	);
}
/** *********************** FETCH COLLECTION FOLLOW UP LOG END ************************ */


export function* operationsSaga() {
	yield all([
		call(onApplicantFetchDetails),
		call(onApplicantFetchDetailsResponseReset),
		call(onProcessApplicantsProfile),
		call(onProcessAppicantsProfileRespReset),
		call(onFetchHighMarkCheckDetailsStart),
		call(onHighMarkCheckDetailsRespReset),
		call(onNapaFollowUpAdd),
		call(onNapaFollowUpAddResponseReset),
		call(onNapaFollowUpFetch),
		call(onNapaFollowUpFetchResponseReset),
	]);
}
