import React from 'react';
import { RouteProps } from 'react-router-dom';
import {
	// dashboardPagesMenu,
	hidePages,
} from '../menu';

import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';
import DashboardBookingHeader from '../pages/_layout/_headers/DashboardBookingHeader';
import DefaultHeader from '../pages/_layout/_headers/DefaultHeader';

const headers: RouteProps[] = [
	{ path: hidePages.login.path, element: null },
	{ path: hidePages.signUp.path, element: null },
	{ path: hidePages.dashboard.path, element: null },
	{ path: hidePages.page404.path, element: null },
	// {
	// 	path: dashboardPagesMenu.dashboard.path,
	// 	// element: <DefaultHeader />
	// },
	{
		path: `*`,
		element: <DefaultHeader />,
	},
];

export default headers;
