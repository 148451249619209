const napaActionTypes = {
	ACTIVE_TAB_CHANGED: 'active_tab_changed',
	UPDATE_STATE_REDUCER_CHANGED: 'update_state_reducer_changed',
	JOB_DETAILS_CHANGED: 'job_details_changed',

	// BASIC DETAILS
	FATHER_CONTACT_VERIFY_CHANGED: 'father_contact_verify_changed',
	MOTHER_CONTACT_VERIFY_CHANGED: 'mother_contact_verify_changed',
	REFERENCE_ONE_CONTACT_VERIFY_CHANGED: 'reference_one_contact_verify_changed',
	REFERENCE_TWO_CONTACT_VERIFY_CHANGED: 'reference_two_contact_verify_changed',
	FATHER_PHONECALL_REVIEW_VERIFY_CHANGED: 'father_phonecall_review_verify_changed',
	MOTHER_PHONECALL_REVIEW_VERIFY_CHANGED: 'mother_phonecall_review_verify_changed',
	REF1_PHONECALL_REVIEW_VERIFY_CHANGED: 'ref1_phonecall_review_verify_changed',
	REF2_PHONECALL_REVIEW_VERIFY_CHANGED: 'ref2_phonecall_review_verify_changed',

	// AADHAR DETAILS
	AADHAR_FRONT_IMAGE_VERIFY_CHANGED: 'aadhar_front_image_verify_changed',
	AADHAR_BACK_IMAGE_VERIFY_CHANGED: 'aadhar_back_image_verify_changed',
	AADHAR_NUMBER_VERIFY_CHANGED: 'aadhar_number_verify_changed',
	AADHAR_NAME_VERIFY_CHANGED: 'aadhar_name_verify_changed',
	AADHAR_FATHER_NAME_VERIFY_CHANGED: 'aadhar_father_name_verify_changed',
	AADHAR_DOB_VERIFY_CHANGED: 'aadhar_dob_verify_changed',
	AADHAR_CITY_VERIFY_CHANGED: 'aadhar_city_verify_changed',
	AADHAR_STATE_VERIFY_CHANGED: 'aadhar_state_verify_changed',
	AADHAR_PINCODE_VERIFY_CHANGED: 'aadhar_pincode_verify_changed',
	AADHAR_ADDRESS_VERIFY_CHANGED: 'aadhar_address_verify_changed',

	// EMPLOYEE DETAILS
	EMPLOYEE_ID_FRONT_IMAGE_VERIFY_CHANGED: 'employee_id_front_image_verify_changed',
	EMPLOYEE_ID_BAcK_IMAGE_VERIFY_CHANGED: 'employee_id_back_image_verify_changed',
	EMPLOYEE_ID_NUMBER_VERIFY_CHANGED: 'employee_id_number_verify_changed',
	EMPLOYEE_ID_NAME_VERIFY_CHANGED: 'employee_id_name_verify_changed',
	EMPLOYEE_DESIGNATION_VERIFY_CHANGED: 'employee_designation_verify_changed',
	EMPLOYEE_ID_DOJ_CHANGED: 'employee_id_doj_verify_changed',
	NAME_ORGANIZATION_VERIFY_CHANGED: 'name_organization_verify_changed',
	YEAR_ORGANIZATION_VERIFY_CHANGED: 'yaer_organization_verify_changed',
	LOCATION_VERIFY_CHANGED: 'location_verify_changed',

	// DRIVING LICENCE DETAILS
	DL_FRONT_IMAGE_VERIFY_CHANGED: 'dl_front_image_verify_changed',
	DL_BAcK_IMAGE_VERIFY_CHANGED: 'dl_back_image_verify_changed',
	DL_NUMBER_VERIFY_CHANGED: 'dl_number_verify_changed',
	DL_EXPIRY_DATE_VERIFY_CHANGED: 'dl_expiry_date_verify_changed',

	// VOTER ID DETAILS
	VOTER_ID_FRONT_IMAGE_VERIFY_CHANGED: 'voter_id_front_image_verify_changed',
	VOTER_ID_BAcK_IMAGE_VERIFY_CHANGED: 'voter_id_back_image_verify_changed',

	// PASSPORT DETAILS
	PASSPORT_FRONT_IMAGE_VERIFY_CHANGED: 'passport_front_image_verify_changed',
	PASSPORT_BAcK_IMAGE_VERIFY_CHANGED: 'passport_back_image_verify_changed',
	PASSPORT_NUMBER_VERIFY_CHANGED: 'passport_number_verify_changed',
	PASSPORT_DATE_ISSUE_VERIFY_CHANGED: 'passport_date_issue_verify_changed',
	PASSPORT_EXPIRY_DATE_VERIFY_CHANGED: 'passport_expiry_date_verify_changed',

	// PHOTO AND VIDEOS
	SELFIE_IMAGE_VERIFY_CHANGED: 'selfie_image_verify_changed',
	SELFIE_VIEOS_VERIFY_CHANGED: 'selfie_vidoe_verify_changed',

	// COLLEGE ID DETAILS
	COLLEGE_ID_FRONT_IMAGE_VERIFY_CHANGED: 'college_id_front_image_verify_changed',
	COLLEGE_ID_BAcK_IMAGE_VERIFY_CHANGED: 'college_id_back_image_verify_changed',
	COLLEGE_NAME_VERIFY_CHANGED: 'college_name_verify_changed',
	STREAM_VERIFY_CHANGED: 'stream_verify_changed',
	SELECT_FROM_YEAR_VERIFY_CHANGED: 'select_from_year_verify_changed',
	SELECT_TO_YEAR_VERIFY_CHANGED: 'select_to_year_verify_changed',
	COURSE_SHORT_FORM_VERIFY_CHANGED: 'course_short_form_verify_changed',
	PROGRAM_CATEGARY_VERIFY_CHANGED: 'program_categary_verify_changed',
	ROLL_NUMBER_VERIFY_CHANGED: 'roll_number_verify_changed',
	MARK_SHEET_VERIFY_CHANGED: 'mark_sheet_verify_changed',

	// PAYSLIP DETAILS
	TOTAL_MONTHLY_SALARY_VERIFY_CHANGED: 'total_monthly_salary_verify_changed',
	ESI_NUMBER_VERIFY_CHANGED: 'esi_number_verify_changed',
	PAYSLIP_MONTH_VERIFY_CHANGED: 'payslip_month_verify_changed',
	PF_DEDUCTION_VERIFY_CHANGED: 'pf_deduction_verify_changed',
	PAYSLIP_DOCUMENT_VERIFY_CHANGED: 'payslip_document_verify_changed',

	// BANK DETAILS
	BANK_STATEMENT_VERIFY_CHANGED: 'bank_statement_verify_changed',

	// PANCARD DETAILS
	PANCARD_IMAGE_VERIFY_CHANGED: 'pancard_image_verify_changed',
	PANCARD_NUMBER_VERIFY_CHANGED: 'pancard_number_verify_changed',

	// ALL MACHED DETAILS
	ALL_MACHED_VERIFY_CHANGED: 'all_mached_verify_changed',
	IS_PHONECALL_ATTEND_VERIFY_CHENGED: 'is_phonecall_attend_verify_changed',

	BASIC_DETAILS_VERIFY_CHANGED: 'basic_details_verify_changed',
	AADHAR_DETAILS_VERIFY_CHENGED: 'aadhar_details_verify_changed',
	COLLEGE_DETAILS_VERIFY_CHANGED: 'college_details_verify_changed',
	EMPLOYEE_DETAILS_VERIFY_CHENGED: 'employee_details_verify_changed',
	PAYSLIP_DETAILS_VERIFY_CHANGED: 'payslip_details_verify_changed',
	PANCARD_DETAILS_VERIFY_CHENGED: 'pancard_details_verify_changed',
	DRIVINGLICENCE_VERIFY_CHANGED: 'drirvingLicence_details_verify_changed',
	VOTERID_DETAILS_VERIFY_CHENGED: 'voterid_details_verify_changed',
	PASSPORT_DETAILS_VERIFY_CHANGED: 'passport_details_verify_changed',
	PHOTO_VIDEO_VERIFY_CHENGED: 'photo_video_details_verify_changed',
	BANK_DETAILS_VERIFY_CHANGED: 'bank_details_verify_changed',

	
	ALL_DOC_MATCH_REVIEW_CHANGED: 'all_doc_match_review_changed',
	PHONE_CALL_REVIEW_CHANGED: 'phone_call_review_changed',
	BASIC_REVIEW_CHANGED: 'basic_review_changed',
	AADHAR_REVIEW_CHANGED: 'aadhar_review_changed',
	PAN_REVIEW_CHANGED: 'pan_review_changed',
	COLLEGE_REVIEW_CHANGED: 'college_review_changed',
	EMPLOYEE_ID_REVIEW_CHANGED: 'employee_id_review_changed',
	PAYSLIP_REVIEW_CHANGED: 'payslip_review_changed',
	DRIVING_LICENCE_REVIEW_CHANGED: 'driving_licence_review_changed',
	PASSPORT_REVIEW_CHANGED: 'passport_review_changed',
	VOTER_ID_REVIEW_CHANGED: 'voter_id_review_changed',
	PHOTO_VIDEO_REVIEW_CHANGED: 'photo_video_review_changed',
	BANK_REVIEW_CHANGED: 'bank_review_changed',
};
export default napaActionTypes;
