import JWT from 'expo-jwt';

type AnyAction = {type: string, [key: string]: any}

// useJwt Token Encode
// @ts-ignore
export const encodeJson = (encodeData: AnyAction, key: AnyAction) => JWT.encode(encodeData, key, { algorithm: 'HS512' });

// useJwt Token Decode
export const decodeJson = (decodeData: AnyAction, key: AnyAction) =>
// @ts-ignore
	JWT.encode(JSON.stringify(decodeData), key, { algorithm: 'HS512' });

// Decode
export const decodeTokenJson = (jsonData: string, key: string) => {
	const decodedData = JWT.decode(jsonData, key);
	return decodedData;
};
