import axios from 'axios';
import { API_URL } from '../const';

export const fetchDetailsForApplicants = (detailsData: JSON) => {
	const data = {
		JobToken: detailsData,
	};
	const url = `${API_URL}nextAssignment`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const processAppicantsProfile = (detailsData: JSON) => {
	const data = {
		UpdateRFRTokenApplicant: detailsData,
	};
	const url = `${API_URL}updateRfrApplicants`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// ASSIGN HO PROFILE


export const finalProcessApplicant = (finalProcessData: JSON) => {
	const data = {
		FinalProcessApplicantProfile: finalProcessData,
	};
	const url = `${API_URL}finalProcessForApplicant`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const checkHighCheckDetails = (detailsData: JSON) => {
	const data = {
		HigMarkCheckDetailsToken: detailsData,
	};
	const url = `${API_URL}fetchHighMarkCheckDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// NAPA FOLLOW UP
export const followUpAddNapa = (addFollowUp: JSON) => {
	const data = {
		addFollowUp: addFollowUp,
	};
	const url = `${API_URL}addNapaFollowUp`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const followUpFetchNapa = (fetchFollowUp: JSON) => {
	const data = {
		FetchFollowUpList: fetchFollowUp,
	};
	const url = `${API_URL}fetchNapaFollowUp`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
