const collectionActionTypes = {
	ADD_COLLECTION_FOLLOWUP_START: 'add_collection_followup_start',
	ADD_COLLECTION_FOLLOWUP_SUCCESS: 'add_collection_followup_success',
	ADD_COLLECTION_FOLLOWUP_FAILURE: 'add_collection_followup_failure',
	ADD_COLLECTION_FOLLOWUP_RESET_START: 'add_collection_followup_reset_start',
	ADD_COLLECTION_FELLOWUP_RESPONSE_CHANGED: 'add_collection_followup_response_changed',

	FETCH_COLLECTION_FOLLOWUP_START: 'fetch_collection_followup_start',
	FETCH_COLLECTION_FOLLOWUP_SUCCESS: 'fetch_collection_followup_success',
	FETCH_COLLECTION_FOLLOWUP_FAILURE: 'fetch_collection_followup_failure',
	FETCH_COLLECTION_FOLLOWUP_RESET_START: 'fetch_collection_followup_reset_start',
	FETCH_COLLECTION_FELLOWUP_RESPONSE_CHANGED: 'fetch_collection_followup_response_changed',

	LOAN_DETAILS_FETCH_START: 'loan_details_fetch_start',
	LOAN_DETAILS_FETCH_SUCCESS: 'loan_details_fetch_success',
	LOAN_DETAILS_FETCH_FAILURE: 'loan_details_fetch_failure',
	LOAN_DETAILS_FETCH_RESET_START: 'loan_details_fetch_reset_start',
	LOAN_DETAILS_FETCH_RESPONSE_CHANGED: 'loan_details_fetch_response_changed',

	LOAN_INFO_FETCH_START: 'loan_info_fetch_start',
	LOAN_INFO_FETCH_SUCCESS: 'loan_info_fetch_success',
	LOAN_INFO_FETCH_FAILURE: 'loan_info_fetch_failure',
	LOAN_INFO_FETCH_RESET_START: 'loan_info_fetch_reset_start',
	LOAN_INFO_FETCH_RESPONSE_CHANGED: 'loan_info_fetch_response_changed',
};

export default collectionActionTypes;
