// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-vars */
import operationActionTypes from './operations.types';

/** ******************* APPLICANT DETAILS FETCH ******************* */
export const fetchApplicantDetailsStart = (napafetch: any) => ({
	type: operationActionTypes.FETCH_APPLICANT_DETAILS_START,
	payload: napafetch,
});

export const fetchApplicanrDetailsSuccess = (napaSuccess: any) => ({
	type: operationActionTypes.FETCH_APPLICANT_DETAILS_SUCCESS,
	payload: napaSuccess,
});

export const fetchApplicantDetailsFailure = (error: any) => ({
	type: operationActionTypes.FETCH_APPLICANT_DETAILS_FAILURE,
	payload: error,
});
export const fetchApplicantDetailsResetStart = () => ({
	type: operationActionTypes.FETCH_NAPA_DETAILS_RESET_RESET_START,
});

export const fetchApplicantDetailsResponseChanged = () => ({
	type: operationActionTypes.FETCH_NAPA_DETAILS_RESET_RESPONSE_CHANGED,
});

/** ******************* PROCEESS NAPA APPLICANTS SHORT ******************* */
export const processApplicantsProfileStart = (updateProcess: any) => ({
	type: operationActionTypes.PROCESS_APPLICANTS_PROFILE_START,
	payload: updateProcess,
});

export const processApplicantsProfileSuccess = (updateSuccess: any) => ({
	type: operationActionTypes.PROCESS_APPLICANTS_PROFILE_SUCCESS,
	payload: updateSuccess,
});

export const processApplicantsProfileFailure = (error: any) => ({
	type: operationActionTypes.PROCESS_APPLICANTS_PROFILE_FAILURE,
	payload: error,
});
export const processApplicantsProfileReset = () => ({
	type: operationActionTypes.PROCESS_APPLICANTS_PROFILE_RESET,
});

export const processApplicantsProfiletResponseChanged = () => ({
	type: operationActionTypes.PROCESS_APPLICANTS_PROFILE_RESPONSE_CHANGED,
});

/** ******************* HIGHMARK CHECK DETAILS ******************* */
export const fetchHighmarkDetailStart = (napaUpdate: any) => ({
	type: operationActionTypes.FETCH_HIGHMARK_DETAILS_START,
	payload: napaUpdate,
});

export const fetchHighmarkDetailSuccess = (napaUpdateSuccess: any) => ({
	type: operationActionTypes.FETCH_HIGHMARK_DETAILS_SUCCESS,
	payload: napaUpdateSuccess,
});

export const fetchHighmarkDetailFailure = (error: any) => ({
	type: operationActionTypes.FETCH_HIGHMARK_DETAILS_FAILURE,
	payload: error,
});
export const fetchHighmarkDetailResetStart = () => ({
	type: operationActionTypes.FETCH_HIGHMARK_DETAILS_RESET_START,
});

export const fetchHighmarkDetailResponseChanged = () => ({
	type: operationActionTypes.FETCH_HIGHMARK_DETAILS_RESPONSE_CHANGED,
});

/****************** GIN SUGGESSTION *************************/
export const ginSuggestionChangesStart = (suggestion: any) => ({
	type: operationActionTypes.SALARIED_GIN_SUGGESION_CHANGED,
	payload: suggestion,
});


/****************** NAPA FOLLOW UP ADD START  *************************/
export const napaFollowUpAddStart = (followUp: any) => ({
	type: operationActionTypes.ADD_NAPA_FOLLOWUP_START,
	payload: followUp,
});

export const napaFollowUpAddSuccess = (followUpSuccess: any) => ({
	type: operationActionTypes.ADD_NAPA_FOLLOWUP_SUCCESS,
	payload: followUpSuccess,
});

export const napaFollowUpAddFailure = (error: any) => ({
	type: operationActionTypes.ADD_NAPA_FOLLOWUP_FAILURE,
	payload: error,
});
export const napaFollowUpAddResetStart = () => ({
	type: operationActionTypes.ADD_NAPA_FOLLOWUP_RESET_START,
});

export const napaFollowUpAddResponseChanged = () => ({
	type: operationActionTypes.ADD_NAPA_FELLOWUP_RESPONSE_CHANGED,
});
/****************** NAPA FOLLOW UP ADD END  *************************/

/****************** NAPA FOLLOW UP FETCH START  *************************/
export const napaFollowUpFetchStart = (followUpFetch: any) => ({
	type: operationActionTypes.FETCH_NAPA_FOLLOWUP_START,
	payload: followUpFetch,
});

export const napaFollowUpFetchSuccess = (followUpFetchSuccess: any) => ({
	type: operationActionTypes.FETCH_NAPA_FOLLOWUP_SUCCESS,
	payload: followUpFetchSuccess,
});

export const napaFollowUpFetchFailure = (error: any) => ({
	type: operationActionTypes.FETCH_NAPA_FOLLOWUP_FAILURE,
	payload: error,
});
export const napaFollowUpFetchResetStart = () => ({
	type: operationActionTypes.FETCH_NAPA_FOLLOWUP_RESET_START,
});

export const napaFollowUpFetchResponseChanged = () => ({
	type: operationActionTypes.FETCH_NAPA_FELLOWUP_RESPONSE_CHANGED,
});
/****************** NAPA FOLLOW UP FETCH END  *************************/