const loginActionTypes = {
	USER_AUTH_CHECK_DONE_CHANGED: 'user_auth_check_done_changed',

	USER_SIGN_IN_START: 'user_sign_in_start',
	USER_SIGN_IN_SUCCESS: 'user_sign_in_success',
	USER_SIGN_IN_FAILURE: 'user_sign_in_failure',
	USER_SIGN_IN_RESPONSE_RESET: 'user_sign_in_response_reset',
	USER_SIGN_IN_RESPONSE_CHANGED: 'user_sign_in_response_changed',

	USER_SIGN_OUT_START: 'user_sign_out_start',
	USER_SIGN_OUT: 'user_sign_out',

	USER_AUTH_VERIFY_START: 'user_auth_verify_start',
	USER_AUTH_VERIFY_SUCCESS: 'user_auth_verify_success',
	USER_AUTH_VERIFY_FAILURE: 'user_auth_verify_failure',
	USER_AUTH_VERIFY_RESPONSE_RESET: 'user_auth_verify_response_reset',
	USER_AUTH_VERIFY_RESPONSE_CHANGED: 'user_auth_verify_response_changed',
};
export default loginActionTypes;
