import JWT from 'expo-jwt';
import { decodeTokenJson } from './enode-decode';

// Decode
export const getDecodedJsonData = (jsonData: any, key: string) => {
	const decodedData = JWT.decode(jsonData, key);
	return decodedData;
};

export const decodeApiResponse = (jsonData: any, key: string) => {
	const decodedData = decodeTokenJson(jsonData, key);
	return decodedData.data;
};

export const docodeNapaResponse = (response: any, key: any) => {
	const docodeVal = decodeTokenJson(response.response, key);
	const val : any = docodeVal.data;
	return val[0];
};

export const docodeResponse = (response: any, key: any) => {
	const docodeVal = decodeTokenJson(response.response, key);
	return docodeVal.data;
};
