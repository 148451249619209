import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import { hidePages } from '../menu';
import Login from '../pages/presentation/auth/Login';

const LANDING = {
	// DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
	APPLICANT_PROFILE_DETAILS: lazy(() => import('../pages/applicant-details/ApplicantDetails')),
};

const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};


const presentation: RouteProps[] = [
	/**
	 * Landing
	 */
	{
		path: hidePages.applicantDetails.path,
		element: <LANDING.APPLICANT_PROFILE_DETAILS />,
	},
	{
		path: hidePages.login.path,
		element: <Login />,
	},
	/** ************************************************** */

	/**
	 * Auth Page
	 */
	{
		path: hidePages.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: hidePages.login.path,
		element: <Login />,
	},
	// {
	// 	path: hidePages.signUp.path,
	// 	element: <Login isSignUp />,
	// },

	/**
	 * END - Auth Page
	 */

	
	// {
	// 	path: `${hidePages.employeeID.path}/:id`,
	// 	element: <APPOINTMENT.EMPLOYEE_VIEW />,
	// },

	/** ************************************************** */
];

const contents = [...presentation];

export default contents;
