// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/default-param-last */
import napaActionTypes from './napa.types';

const INITIAL_STATE: any = {
	activeTab: 'LetStart',

	fatherContactVerify: '',
	motherContactVerify: '',
	ref1ContactVerify: '',
	ref2ContactVerify: '',
	fatherNumberReview: '',
	motherNumberReview: '',
	ref1NumberReview: '',
	ref2NumberReview: '',

	aadharNumberVerify: '',
	aadharNameVerify: '',
	aadharFatherNameVerify: '',
	aadharDobVerify: '',
	aadharCityVerify: '',
	aadharStateVerify: '',
	aadharPinCodeVerify: '',
	aadharAddressVerify: '',
	aadharFrontImageVerify: '',
	aadharBackImageVerify: '',

	employeeIdFrontImageVerify: '',
	employeeIdBackImageVerify: '',
	employeeIdNumberVerify: '',
	employeeIdNameVerify: '',
	employeeDesignationVerify: '',
	employeeDojVerify: '',
	nameOrganizationVerify: '',
	yearOrganizationVerify: '',
	locationVerify: '',

	dlFrontImageVerify: '',
	dlBackImageVerify: '',
	dlNumberVerify: '',
	dlExpiryDateVerify: '',

	voterIdFrontImageVerify: '',
	voterIdBackImageVerify: '',

	passportFrontImageVerify: '',
	passportBackImageVerify: '',
	passportNumberVerify: '',
	passportDateIssueVerify: '',
	passportExpiryDateVerify: '',

	selfieImageVerify: '',
	selfieVideoVerify: '',

	collegeNameVerify: '',
	streamVerify: '',
	selectFromyearVerify: '',
	selectToyearVerify: '',
	courseShortFormVerify: '',
	programCategaryVerify: '',
	rollNumberVerify: '',
	collegeIdFrontImageVerify: '',
	collegeIdBackImageVerify: '',
	marksheetOrFeesVerify: '',

	totalMonthlySalaryVerify: '',
	esinumberVerify: '',
	paysilpMonthVerify: '',
	pfDeductionVerify: '',
	payslipDocumentVerify: '',

	bankStatementVerify: '',

	pancardImageVerify: '',
	pancardNumberVerify: '',

	allMachedVerify: '',
	isPhoneCallVerify: '',

	basicDetailsVerify: 0,
	aadharDeatilsVerified: 0,
	collegeIdDetailsVerified: 0,
	employeeIdDetailsVerified: 0,
	payslipDetailsVerified: 0,
	pancardDetailsVerified: 0,
	drirvingLicenceDetailsVerified: 0,
	voterIdDetailsVerified: 0,
	passportDetailsVerified: 0,
	photoVideoDetailsVerified: 0,
	bankDetailDetailsVerified: 0,

	allDocumentMatchedReview: '',
	phoneCallReview: '',
	basicDetailsReview: '',
	aadharReview: '',
	panCardReview: '',
	collegeReview: '',
	employeeIdReview: '',
	paySlipReview: '',
	dirvingLicenceReview: '',
	passportReview: '',
	voterIDCardReview: '',
	photosAndVideoReview: '',
	bankDetailsReview: '',

	jobDetails: '',
};

const napaReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case napaActionTypes.ACTIVE_TAB_CHANGED:
			return {
				...state,
				activeTab: action.payload,
			};

		case napaActionTypes.UPDATE_STATE_REDUCER_CHANGED:
			return action.payload;

		/** *************** BASIC DETAILS ******************* */
		case napaActionTypes.FATHER_CONTACT_VERIFY_CHANGED:
			return {
				...state,
				fatherContactVerify: action.payload,
			};

		case napaActionTypes.MOTHER_CONTACT_VERIFY_CHANGED:
			return {
				...state,
				motherContactVerify: action.payload,
			};

		case napaActionTypes.REFERENCE_ONE_CONTACT_VERIFY_CHANGED:
			return {
				...state,
				ref1ContactVerify: action.payload,
			};

		case napaActionTypes.REFERENCE_TWO_CONTACT_VERIFY_CHANGED:
			return {
				...state,
				ref2ContactVerify: action.payload,
			};

		case napaActionTypes.FATHER_PHONECALL_REVIEW_VERIFY_CHANGED:
			return {
				...state,
				fatherNumberReview: action.payload,
			};

		case napaActionTypes.MOTHER_PHONECALL_REVIEW_VERIFY_CHANGED:
			return {
				...state,
				motherNumberReview: action.payload,
			};

		case napaActionTypes.REF1_PHONECALL_REVIEW_VERIFY_CHANGED:
			return {
				...state,
				ref1NumberReview: action.payload,
			};

		case napaActionTypes.REF2_PHONECALL_REVIEW_VERIFY_CHANGED:
			return {
				...state,
				ref2NumberReview: action.payload,
			};

		/** *************** AADHAR VERIFY DETAILS ******************* */

		case napaActionTypes.AADHAR_FRONT_IMAGE_VERIFY_CHANGED:
			return {
				...state,
				aadharFrontImageVerify: action.payload,
			};

		case napaActionTypes.AADHAR_BACK_IMAGE_VERIFY_CHANGED:
			return {
				...state,
				aadharBackImageVerify: action.payload,
			};

		case napaActionTypes.AADHAR_NUMBER_VERIFY_CHANGED:
			return {
				...state,
				aadharNumberVerify: action.payload,
			};

		case napaActionTypes.AADHAR_NAME_VERIFY_CHANGED:
			return {
				...state,
				aadharNameVerify: action.payload,
			};

		case napaActionTypes.AADHAR_FATHER_NAME_VERIFY_CHANGED:
			return {
				...state,
				aadharFatherNameVerify: action.payload,
			};

		case napaActionTypes.AADHAR_DOB_VERIFY_CHANGED:
			return {
				...state,
				aadharDobVerify: action.payload,
			};

		case napaActionTypes.AADHAR_CITY_VERIFY_CHANGED:
			return {
				...state,
				aadharCityVerify: action.payload,
			};

		case napaActionTypes.AADHAR_STATE_VERIFY_CHANGED:
			return {
				...state,
				aadharStateVerify: action.payload,
			};

		case napaActionTypes.AADHAR_PINCODE_VERIFY_CHANGED:
			return {
				...state,
				aadharPinCodeVerify: action.payload,
			};

		case napaActionTypes.AADHAR_ADDRESS_VERIFY_CHANGED:
			return {
				...state,
				aadharAddressVerify: action.payload,
			};

		/** *************** EMPLOYEE VERIFY DETAILS ******************* */

		case napaActionTypes.EMPLOYEE_ID_FRONT_IMAGE_VERIFY_CHANGED:
			return {
				...state,
				employeeIdFrontImageVerify: action.payload,
			};

		case napaActionTypes.EMPLOYEE_ID_BAcK_IMAGE_VERIFY_CHANGED:
			return {
				...state,
				employeeIdBackImageVerify: action.payload,
			};

		case napaActionTypes.EMPLOYEE_ID_NUMBER_VERIFY_CHANGED:
			return {
				...state,
				employeeIdNumberVerify: action.payload,
			};

		case napaActionTypes.EMPLOYEE_ID_NAME_VERIFY_CHANGED:
			return {
				...state,
				employeeIdNameVerify: action.payload,
			};

		case napaActionTypes.EMPLOYEE_DESIGNATION_VERIFY_CHANGED:
			return {
				...state,
				employeeDesignationVerify: action.payload,
			};

		case napaActionTypes.EMPLOYEE_ID_DOJ_CHANGED:
			return {
				...state,
				employeeDojVerify: action.payload,
			};

		case napaActionTypes.NAME_ORGANIZATION_VERIFY_CHANGED:
			return {
				...state,
				nameOrganizationVerify: action.payload,
			};

		case napaActionTypes.YEAR_ORGANIZATION_VERIFY_CHANGED:
			return {
				...state,
				yearOrganizationVerify: action.payload,
			};

		case napaActionTypes.LOCATION_VERIFY_CHANGED:
			return {
				...state,
				locationVerify: action.payload,
			};

		/** *************** DRIVING LICENCE DETAILS ******************* */

		case napaActionTypes.DL_FRONT_IMAGE_VERIFY_CHANGED:
			return {
				...state,
				dlFrontImageVerify: action.payload,
			};

		case napaActionTypes.DL_BAcK_IMAGE_VERIFY_CHANGED:
			return {
				...state,
				dlBackImageVerify: action.payload,
			};

		case napaActionTypes.DL_NUMBER_VERIFY_CHANGED:
			return {
				...state,
				dlNumberVerify: action.payload,
			};

		case napaActionTypes.DL_EXPIRY_DATE_VERIFY_CHANGED:
			return {
				...state,
				dlExpiryDateVerify: action.payload,
			};

		/** *************** DRIVING LICENCE DETAILS ******************* */

		case napaActionTypes.VOTER_ID_FRONT_IMAGE_VERIFY_CHANGED:
			return {
				...state,
				voterIdFrontImageVerify: action.payload,
			};

		case napaActionTypes.VOTER_ID_BAcK_IMAGE_VERIFY_CHANGED:
			return {
				...state,
				voterIdBackImageVerify: action.payload,
			};

		/** *************** DRIVING LICENCE DETAILS ******************* */

		case napaActionTypes.PASSPORT_FRONT_IMAGE_VERIFY_CHANGED:
			return {
				...state,
				passportFrontImageVerify: action.payload,
			};

		case napaActionTypes.PASSPORT_BAcK_IMAGE_VERIFY_CHANGED:
			return {
				...state,
				passportBackImageVerify: action.payload,
			};

		case napaActionTypes.PASSPORT_NUMBER_VERIFY_CHANGED:
			return {
				...state,
				passportNumberVerify: action.payload,
			};

		case napaActionTypes.PASSPORT_DATE_ISSUE_VERIFY_CHANGED:
			return {
				...state,
				passportDateIssueVerify: action.payload,
			};

		case napaActionTypes.PASSPORT_EXPIRY_DATE_VERIFY_CHANGED:
			return {
				...state,
				passportExpiryDateVerify: action.payload,
			};

		/** *************** PHOTO AND VIDEOS ******************* */

		case napaActionTypes.SELFIE_IMAGE_VERIFY_CHANGED:
			return {
				...state,
				selfieImageVerify: action.payload,
			};

		case napaActionTypes.SELFIE_VIEOS_VERIFY_CHANGED:
			return {
				...state,
				selfieVideoVerify: action.payload,
			};

		/** *************** EMPLOYEE VERIFY DETAILS ******************* */

		case napaActionTypes.COLLEGE_ID_FRONT_IMAGE_VERIFY_CHANGED:
			return {
				...state,
				collegeIdFrontImageVerify: action.payload,
			};

		case napaActionTypes.COLLEGE_ID_BAcK_IMAGE_VERIFY_CHANGED:
			return {
				...state,
				collegeIdBackImageVerify: action.payload,
			};

		case napaActionTypes.COLLEGE_NAME_VERIFY_CHANGED:
			return {
				...state,
				collegeNameVerify: action.payload,
			};

		case napaActionTypes.STREAM_VERIFY_CHANGED:
			return {
				...state,
				streamVerify: action.payload,
			};

		case napaActionTypes.SELECT_FROM_YEAR_VERIFY_CHANGED:
			return {
				...state,
				selectFromyearVerify: action.payload,
			};

		case napaActionTypes.SELECT_TO_YEAR_VERIFY_CHANGED:
			return {
				...state,
				selectToyearVerify: action.payload,
			};

		case napaActionTypes.COURSE_SHORT_FORM_VERIFY_CHANGED:
			return {
				...state,
				courseShortFormVerify: action.payload,
			};

		case napaActionTypes.PROGRAM_CATEGARY_VERIFY_CHANGED:
			return {
				...state,
				programCategaryVerify: action.payload,
			};

		case napaActionTypes.ROLL_NUMBER_VERIFY_CHANGED:
			return {
				...state,
				rollNumberVerify: action.payload,
			};

		case napaActionTypes.MARK_SHEET_VERIFY_CHANGED:
			return {
				...state,
				marksheetOrFeesVerify: action.payload,
			};

		/** *************** PAYSILIP VERIFY DETAILS ******************* */

		case napaActionTypes.TOTAL_MONTHLY_SALARY_VERIFY_CHANGED:
			return {
				...state,
				totalMonthlySalaryVerify: action.payload,
			};

		case napaActionTypes.ESI_NUMBER_VERIFY_CHANGED:
			return {
				...state,
				esinumberVerify: action.payload,
			};

		case napaActionTypes.PAYSLIP_MONTH_VERIFY_CHANGED:
			return {
				...state,
				paysilpMonthVerify: action.payload,
			};

		case napaActionTypes.PF_DEDUCTION_VERIFY_CHANGED:
			return {
				...state,
				pfDeductionVerify: action.payload,
			};

		case napaActionTypes.PAYSLIP_DOCUMENT_VERIFY_CHANGED:
			return {
				...state,
				payslipDocumentVerify: action.payload,
			};

		/** *************** PAYSILIP VERIFY DETAILS ******************* */

		case napaActionTypes.BANK_STATEMENT_VERIFY_CHANGED:
			return {
				...state,
				bankStatementVerify: action.payload,
			};

		/** *************** PANCARD DETAILS ******************* */

		case napaActionTypes.PANCARD_IMAGE_VERIFY_CHANGED:
			return {
				...state,
				pancardImageVerify: action.payload,
			};

		case napaActionTypes.PANCARD_NUMBER_VERIFY_CHANGED:
			return {
				...state,
				pancardNumberVerify: action.payload,
			};

		/** *************** PANCARD DETAILS ******************* */

		case napaActionTypes.ALL_MACHED_VERIFY_CHANGED:
			return {
				...state,
				allMachedVerify: action.payload,
			};

		case napaActionTypes.IS_PHONECALL_ATTEND_VERIFY_CHENGED:
			return {
				...state,
				isPhoneCallVerify: action.payload,
			};

		/** *************** ALL DETAILS VERIFY ******************* */
		case napaActionTypes.BASIC_DETAILS_VERIFY_CHANGED:
			return {
				...state,
				basicDetailsVerify: action.payload,
				basicDetailsReview: action.payload === 1 ? '' : state.basicDetailsReview,
			};

		case napaActionTypes.AADHAR_DETAILS_VERIFY_CHENGED:
			return {
				...state,
				aadharDeatilsVerified: action.payload,
				aadharReview: action.payload === 1 ? '' : state.aadharReview,
			};

		case napaActionTypes.COLLEGE_DETAILS_VERIFY_CHANGED:
			return {
				...state,
				collegeIdDetailsVerified: action.payload,
				collegeReview: action.payload === 1 ? '' : state.collegeReview,
			};

		case napaActionTypes.EMPLOYEE_DETAILS_VERIFY_CHENGED:
			return {
				...state,
				employeeIdDetailsVerified: action.payload,
				employeeIdReview: action.payload === 1 ? '' : state.employeeIdReview,
			};

		case napaActionTypes.PAYSLIP_DETAILS_VERIFY_CHANGED:
			return {
				...state,
				payslipDetailsVerified: action.payload,
				paySlipReview: action.payload === 1 ? '' : state.paySlipReview,
			};

		case napaActionTypes.PANCARD_DETAILS_VERIFY_CHENGED:
			return {
				...state,
				pancardDetailsVerified: action.payload,
				panCardReview: action.payload === 1 ? '' : state.panCardReview,
			};

		case napaActionTypes.DRIVINGLICENCE_VERIFY_CHANGED:
			return {
				...state,
				drirvingLicenceDetailsVerified: action.payload,
				dirvingLicenceReview: action.payload === 1 ? '' : state.dirvingLicenceReview,
			};

		case napaActionTypes.VOTERID_DETAILS_VERIFY_CHENGED:
			return {
				...state,
				voterIdDetailsVerified: action.payload,
				voterIDCardReview: action.payload === 1 ? '' : state.voterIDCardReview,
			};

		case napaActionTypes.PASSPORT_DETAILS_VERIFY_CHANGED:
			return {
				...state,
				passportDetailsVerified: action.payload,
				passportReview: action.payload === 1 ? '' : state.passportReview,
			};

		case napaActionTypes.PHOTO_VIDEO_VERIFY_CHENGED:
			return {
				...state,
				photoVideoDetailsVerified: action.payload,
				photosAndVideoReview: action.payload === 1 ? '' : state.photosAndVideoReview,
			};

		case napaActionTypes.BANK_DETAILS_VERIFY_CHANGED:
			return {
				...state,
				bankDetailDetailsVerified: action.payload,
				bankDetailsReview: action.payload === 1 ? '' : state.bankDetailsReview,
			};

		case napaActionTypes.JOB_DETAILS_CHANGED:
			return {
				...state,
				jobDetails: action.payload,
			};


		/************** REVIEW CHANGED DETAILS *************/
		case napaActionTypes.ALL_DOC_MATCH_REVIEW_CHANGED:
			return {
				...state,
				allDocumentMatchedReview: action.payload,
			};

		case napaActionTypes.PHONE_CALL_REVIEW_CHANGED:
			return {
				...state,
				phoneCallReview: action.payload,
			};

		case napaActionTypes.BASIC_REVIEW_CHANGED:
			return {
				...state,
				basicDetailsReview: action.payload,
			};

		case napaActionTypes.AADHAR_REVIEW_CHANGED:
			return {
				...state,
				aadharReview: action.payload,
			};

		case napaActionTypes.PAN_REVIEW_CHANGED:
			return {
				...state,
				panCardReview: action.payload,
			};

		case napaActionTypes.COLLEGE_REVIEW_CHANGED:
			return {
				...state,
				collegeReview: action.payload,
			};
		

		case napaActionTypes.EMPLOYEE_ID_REVIEW_CHANGED:
			return {
				...state,
				employeeIdReview: action.payload,
			};

		case napaActionTypes.PAYSLIP_REVIEW_CHANGED:
			return {
				...state,
				paySlipReview: action.payload,
			};

		case napaActionTypes.DRIVING_LICENCE_REVIEW_CHANGED:
			return {
				...state,
				dirvingLicenceReview: action.payload,
			};

		case napaActionTypes.PASSPORT_REVIEW_CHANGED:
			return {
				...state,
				passportReview: action.payload,
			};

		case napaActionTypes.VOTER_ID_REVIEW_CHANGED:
			return {
				...state,
				voterIDCardReview: action.payload,
			};

		case napaActionTypes.PHOTO_VIDEO_REVIEW_CHANGED:
			return {
				...state,
				photosAndVideoReview: action.payload,
			};

		case napaActionTypes.BANK_REVIEW_CHANGED:
			return {
				...state,
				bankDetailsReview: action.payload,
			};
			

		default:
			return state;
	}
};

export default napaReducer;
