import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { TourProvider } from '@reactour/tour';
import dayjs from 'dayjs';
import moment from 'moment';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ToastContainer } from 'react-toastify';
import { useFormik } from 'formik';
import showNotification from '../components/extras/showNotification';
import Icon from '../components/icon/Icon';
import Input from '../components/bootstrap/forms/Input';
import FormGroup from '../components/bootstrap/forms/FormGroup';
import Button from '../components/bootstrap/Button';
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalFooter } from '../components/bootstrap/Modal';
import { hidePages } from '../menu';
import ThemeContext from '../contexts/themeContext';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import steps, { styles } from '../steps';
import AsideRoutes from '../layout/Aside/AsideRoutes';
import { ToastCloseButton } from '../components/bootstrap/Toasts';
import {
	userSignInLogOutStart,
	userAuthVerifyStart,
	userAuthVerifyResponseResetStart,
	authCheckDoneChangeStart
} from '../redux/login/login.action';
import { selectedActiveTab } from '../redux/napa/napa.selector';
import {
	selectAuthCheck,
	getLoginObjDetails,
	selectCurrentDecodedUser,
	selectAuthVerifyResponse,
	selectCurrentUser,
} from '../redux/login/login.selector';

const events = [
	"load",
	"mousemove",
	"mousedown",
	"click",
	"scroll",
	"keypress",
 ];
 
const App = () => {
	getOS();

	dayjs.extend(localizedFormat);
	dayjs.extend(relativeTime);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const loginResponse = useSelector(selectCurrentUser);
	const loginRespsArr = useSelector(selectCurrentDecodedUser);
	const loginData = useSelector(getLoginObjDetails);	
	const selectedTab = useSelector(selectedActiveTab);
	const authVerifyResp = useSelector(selectAuthVerifyResponse);
	const authCheckDone = useSelector(selectAuthCheck);
	const [authModal, setAuthModal] = useState<boolean>(false);
	const [selectTabVal, setSelectedTab] = useState<string>('');
	const [loginTime, setLoginTime] = useState<string>('');
	/**
	 * Dark Mode
	 */
	let timer: string | number | NodeJS.Timeout | undefined;
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
			document.documentElement.setAttribute('data-bs-theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
			document.documentElement.removeAttribute('data-bs-theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	
	// this function sets the timer that logs out the user after 10 secs
	const handleLogoutTimer = () => {
		timer = setTimeout(() => {
			// clears any pending timer.
			resetTimer();
			// Listener clean up. Removes the existing event listener from the window
			Object.values(events).forEach((item) => {
				window.removeEventListener(item, resetTimer);
			});
			if (loginRespsArr !== null && loginRespsArr !== undefined && loginRespsArr.length > 0) {
				logoutAction();
			}
		}, 900000); // 10000ms = 900000 10secs. You can change the time.
	};

	// this resets the timer if it exists.
	const resetTimer = () => {
		if (timer) clearTimeout(timer);
	};
	
	// when component mounts, it adds an event listeners to the window
	// each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
	// However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
	useEffect(() => {
		Object.values(events).forEach((item) => {
			window.addEventListener(item, () => {
				resetTimer();
				handleLogoutTimer();
			});
		});		
	}, []);
	
	useEffect(() => {
		if (loginResponse != null) {
			if (loginResponse.statusCode === '01') {
				dispatch(authCheckDoneChangeStart(0));
				const loginDateTime = moment().format("YYYY-MM-DD HH:mm:ss");
				setLoginTime(loginDateTime);
				setAuthModal(true);
			}
		}

		if (loginRespsArr !== null && loginRespsArr !== undefined && loginRespsArr.length > 0 && selectTabVal !== selectedTab) {
			setSelectedTab(selectTabVal);
			const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
			const now = moment(currentTime,"YYYY-MM-DD HH:mm:ss");
			const prev = moment(loginTime, "YYYY-MM-DD HH:mm:ss");
			// var diffHrs = moment(now).diff(moment(prev), 'hours');
			var diffHrs = moment(now).diff(moment(prev), 'minutes');
			console.log(diffHrs);
			if (diffHrs >= 1) {
				setAuthModal(true);
				dispatch(authCheckDoneChangeStart(0));
			} else if (authCheckDone === 0) {
				setAuthModal(true);
			}
		}

		if (authVerifyResp !== null && authVerifyResp !== undefined) {
			if (authVerifyResp.statusCode === '01') {
				const loginDateTime = moment().format("YYYY-MM-DD HH:mm:ss");
				setLoginTime(loginDateTime);
				dispatch(authCheckDoneChangeStart(1));
				setAuthModal(false);		
			} else {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon
							icon='Error'
							size='lg'
							className='me-1'
						/>
						<span>Success</span>
					</span>, 'Google Authentication Failed', 'danger'
				);	
			}
			dispatch(userAuthVerifyResponseResetStart());
		}
	}, [loginResponse, selectedTab, authVerifyResp]);
	
	// logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
	const logoutAction = () => {
		dispatch(userSignInLogOutStart());
		navigate(`../${hidePages.login.path}`);
	};

	const formik = useFormik({
		initialValues: {
			authKeyNumber: '',
			secrateAuth: '',
			staffID: '',
			userID: '',
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		onSubmit: (values) => {
			const { secrateAuth, userID, staffID } = loginData;
			values.secrateAuth = secrateAuth;
			values.userID = userID;
			values.staffID = staffID;
			dispatch(userAuthVerifyStart(values));
		},
	});

	return (
		<ThemeProvider theme={theme}>
			<TourProvider steps={steps} styles={styles} showNavigation={false} showBadge={false}>
				<div
					ref={ref}
					className='app'
					style={{
						backgroundColor: fullScreenStatus ? 'var(--bs-body-bg)' : undefined,
						zIndex: fullScreenStatus ? 1 : undefined,
						overflow: fullScreenStatus ? 'scroll' : undefined,
					}}>
					<AsideRoutes />
					<Wrapper />

					<Modal
						setIsOpen={setAuthModal}
						isOpen={authModal}
						isStaticBackdrop
						titleId='auth-modal'>
						<ModalHeader>
							<ModalTitle id='auth-modal'>Verify Authentication</ModalTitle>
						</ModalHeader>

						
						<ModalBody>
							<div className='row mb-3'>
								<div className='col-12'>
									<FormGroup
										id='authKeyNumber'
										label='Enter the Google Authentication 6 digit key'>
										<Input
											size='lg'
											type='number'
											placeholder='Enter the Google Authentication 6 digit key'
											onChange={formik.handleChange}
											value={formik.values.authKeyNumber}
										/>
									</FormGroup>
								</div>
							</div>
						</ModalBody>
						<ModalFooter className='px-4 pb-4'>
							<Button
								icon='CheckCircleOutline'
								color='dark'
								type='submit'
								onClick={formik.handleSubmit}>
								verify Auth Kay
							</Button>
						</ModalFooter>
					</Modal>
				</div>
				<Portal id='portal-notification'>
					<ReactNotifications />
				</Portal>
				<ToastContainer closeButton={ToastCloseButton} toastClassName='toast show' />
			</TourProvider>
		</ThemeProvider>
	);
};

export default App;
