import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import loginReducer from './login/login.reducer';
import napaReducer from './napa/napa.reducer';
import operationReducer from './operations/operations.reducer';
import collectionReducer from './collection/collection.reducer';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['login'],
};
const rootReducer = combineReducers({
	login: loginReducer,
	napa: napaReducer,
	operation: operationReducer,
	collection: collectionReducer,
});

export default persistReducer(persistConfig, rootReducer);
