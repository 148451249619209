import axios from 'axios';
import { API_URL } from '../const';

export const userSignIn = (loginData: JSON) => {
	const data = {
		LoginCheckToken: loginData,
	};
	const url = `${API_URL}loginCheck`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/**************** USER AUTH CHECK START ****************/
export const authUserVerification = (authLogVerifiy: JSON) => {
	const data = {
		UserCheckAuth: authLogVerifiy,
	};
	const url = `${API_URL}userAuthVerification`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
/**************** USER AUTH CHECK END ****************/