import collectionActionTypes from './collection.types';

export const collectionFollowUpAddStart = (followUp: any) => ({
	type: collectionActionTypes.ADD_COLLECTION_FOLLOWUP_START,
	payload: followUp,
});

export const collectionFollowUpAddSuccess = (followUpSuccess: any) => ({
	type: collectionActionTypes.ADD_COLLECTION_FOLLOWUP_SUCCESS,
	payload: followUpSuccess,
});

export const collectionFollowUpAddFailure = (error: any) => ({
	type: collectionActionTypes.ADD_COLLECTION_FOLLOWUP_FAILURE,
	payload: error,
});
export const collectionFollowUpAddResetStart = () => ({
	type: collectionActionTypes.ADD_COLLECTION_FOLLOWUP_RESET_START,
});

export const collectionFollowUpAddResponseChanged = () => ({
	type: collectionActionTypes.ADD_COLLECTION_FELLOWUP_RESPONSE_CHANGED,
});

// COLLECTION FOLLOW UP FETCH
export const collectionFollowUpFetchStart = (followUpFetch: any) => ({
	type: collectionActionTypes.FETCH_COLLECTION_FOLLOWUP_START,
	payload: followUpFetch,
});

export const collectionFollowUpFetchSuccess = (followUpFetchSuccess: any) => ({
	type: collectionActionTypes.FETCH_COLLECTION_FOLLOWUP_SUCCESS,
	payload: followUpFetchSuccess,
});

export const collectionFollowUpFetchFailure = (error: any) => ({
	type: collectionActionTypes.FETCH_COLLECTION_FOLLOWUP_FAILURE,
	payload: error,
});
export const collectionFollowUpFetchResetStart = () => ({
	type: collectionActionTypes.FETCH_COLLECTION_FOLLOWUP_RESET_START,
});

export const collectionFollowUpFetchResponseChanged = () => ({
	type: collectionActionTypes.FETCH_COLLECTION_FELLOWUP_RESPONSE_CHANGED,
});

// LOAN DETAILS FETCH
export const loanDetailsFetchStart = (collection: any) => ({
	type: collectionActionTypes.LOAN_DETAILS_FETCH_START,
	payload: collection,
});

export const loanDetailsFetchSuccess = (collectionSuccess: any) => ({
	type: collectionActionTypes.LOAN_DETAILS_FETCH_SUCCESS,
	payload: collectionSuccess,
});

export const loanDetailsFetchFailure = (error: any) => ({
	type: collectionActionTypes.LOAN_DETAILS_FETCH_FAILURE,
	payload: error,
});
export const loanDetailsFetchResetStart = () => ({
	type: collectionActionTypes.LOAN_DETAILS_FETCH_RESET_START,
});

export const loanDetailsFetchResponseChanged = () => ({
	type: collectionActionTypes.LOAN_DETAILS_FETCH_RESPONSE_CHANGED,
});

// LOAN INFO DETAILS FETCH
export const loanInfoFetchStart = (collection: any) => ({
	type: collectionActionTypes.LOAN_INFO_FETCH_START,
	payload: collection,
});

export const loanInfoFetchSuccess = (collectionSuccess: any) => ({
	type: collectionActionTypes.LOAN_INFO_FETCH_SUCCESS,
	payload: collectionSuccess,
});

export const loanInfoFetchFailure = (error: any) => ({
	type: collectionActionTypes.LOAN_INFO_FETCH_FAILURE,
	payload: error,
});
export const loanInfoFetchResetStart = () => ({
	type: collectionActionTypes.LOAN_INFO_FETCH_RESET_START,
});

export const loanInfoFetchResponseChanged = () => ({
	type: collectionActionTypes.LOAN_INFO_FETCH_RESPONSE_CHANGED,
});
