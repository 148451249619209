// export const dashboardPagesMenu = {
// 	pages: {
// 		id: 'dashboards',
// 		text: 'Dashboards',
// 		icon: 'Extension',
// 	},
// 	dashboard: {
// 		id: 'dashboard',
// 		text: 'Dashboard',
// 		path: '/',
// 		icon: 'Dashboard',
// 		subMenu: null,
// 	},
// };

export const hidePages = {
	login: {
		id: 'login',
		text: 'Login',
		path: 'auth-pages/login',
		icon: 'Login',
		hide: true,
	},
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		hide: true,
	},
	applicantDetails: {
		id: 'applicant',
		text: 'Applicant Details',
		path: '/',
		icon: 'Dashboard',
		hide: true,
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'auth-pages/sign-up',
		icon: 'PersonAdd',
		hide: true,
	},

	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
		hide: true,
	},
}



export const productsExampleMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};
