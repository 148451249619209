// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/no-duplicates */
import { all, call } from 'redux-saga/effects';

import { loginSaga } from './login/login.saga';
import { operationsSaga } from './operations/operations.saga';
import { collectionSaga } from './collection/collection.saga';

export default function* rootSaga() {
	yield all([
		call(loginSaga),
		call(operationsSaga),
		call(collectionSaga)
	]);
}
