// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/default-param-last */
import operationActionTypes from './operations.types';
import { docodeNapaResponse, docodeResponse } from '../_common/common.utils';

const INITIAL_STATE: any = {
	napaDetailsFetchResponse: null,
	napaDetailsArr: [],

	highMarkCheckDetailsResponse: null,
	highMarkCheckDetailsArr: [],

	proccessProfileResponse: null,

	ginSuggesion: null,
	
	addNapaFollowUpResponse: null,
	fetchNapaFollowUpResponse: null,
	fetchNapaFollowUpArr: [],
};

const operationReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case operationActionTypes.FETCH_APPLICANT_DETAILS_START:
			return {
				...state,
				napaDetailsFetchResponse: null,
				napaDetailsArr: [],
			};

		case operationActionTypes.FETCH_APPLICANT_DETAILS_SUCCESS:
			return {
				...state,
				napaDetailsFetchResponse: action.payload,
				napaDetailsArr: docodeNapaResponse(action.payload, 'NextFetchData'),
			};

		case operationActionTypes.FETCH_APPLICANT_DETAILS_FAILURE:
			return {
				...state,
				napaDetailsFetchResponse: action.payload,
			};

		case operationActionTypes.FETCH_NAPA_DETAILS_RESET_RESPONSE_CHANGED:
			return {
				...state,
				napaDetailsFetchResponse: null,
			};

		/** *************** PROCEESS NAPA APPLICANTS SHORT ******************* */

		case operationActionTypes.PROCESS_APPLICANTS_PROFILE_SUCCESS:
			return {
				...state,
				proccessProfileResponse: action.payload,
				napaDetailsFetchResponse: null,
				napaDetailsArr: [],
				highMarkCheckDetailsResponse: null,
				highMarkCheckDetailsArr: [],
				ginSuggesion: null
			};

		case operationActionTypes.PROCESS_APPLICANTS_PROFILE_FAILURE:
			return {
				...state,
				proccessProfileResponse: action.payload,
			};

		case operationActionTypes.PROCESS_APPLICANTS_PROFILE_RESPONSE_CHANGED:
			return {
				...state,
				proccessProfileResponse: null,
			};

		/** *************** HIGHMARK CHECK DETAILS ******************* */

		case operationActionTypes.FETCH_HIGHMARK_DETAILS_SUCCESS:
			return {
				...state,
				highMarkCheckDetailsResponse: action.payload,
				highMarkCheckDetailsArr: docodeResponse(
					action.payload,
					'HighMark_Check_Details',
				),
			};

		case operationActionTypes.FETCH_HIGHMARK_DETAILS_FAILURE:
			return {
				...state,
				highMarkCheckDetailsResponse: action.payload,
			};

		case operationActionTypes.FETCH_HIGHMARK_DETAILS_RESPONSE_CHANGED:
			return {
				...state,
				highMarkCheckDetailsResponse: null,
			};

		case operationActionTypes.SALARIED_GIN_SUGGESION_CHANGED:
			return {
				...state,
				ginSuggesion: action.payload,
			};

		// FOLLOW UP NAPA
		case operationActionTypes.ADD_NAPA_FOLLOWUP_SUCCESS:
			return {
				...state,
				addNapaFollowUpResponse: action.payload,
			};

		case operationActionTypes.ADD_NAPA_FOLLOWUP_FAILURE:
			return {
				...state,
				addNapaFollowUpResponse: action.payload,
			};

		case operationActionTypes.ADD_NAPA_FELLOWUP_RESPONSE_CHANGED:
			return {
				...state,
				addNapaFollowUpResponse: null,
			};

		case operationActionTypes.FETCH_NAPA_FOLLOWUP_START:
			return {
				...state,
				fetchNapaFollowUpResponse: null,
				fetchNapaFollowUpArr: [],
			};

		case operationActionTypes.FETCH_NAPA_FOLLOWUP_SUCCESS:
			return {
				...state,
				fetchNapaFollowUpResponse: action.payload,
				fetchNapaFollowUpArr: docodeResponse(action.payload, 'followUpFetch'),
			};

		case operationActionTypes.FETCH_NAPA_FOLLOWUP_FAILURE:
			return {
				...state,
				fetchNapaFollowUpResponse: action.payload,
			};

		case operationActionTypes.FETCH_NAPA_FELLOWUP_RESPONSE_CHANGED:
			return {
				...state,
				fetchNapaFollowUpResponse: null,
			};

		default:
			return state;
	}
};

export default operationReducer;
