import loginActionTypes from './login.types';

export const authCheckDoneChangeStart = (authCheck: any) => ({
	type: loginActionTypes.USER_AUTH_CHECK_DONE_CHANGED,
	payload: authCheck,
});

/************ USER LOGIN CHECK SATRT *****************/
export const userSignInStart = (userCredentials: any) => ({
	type: loginActionTypes.USER_SIGN_IN_START,
	payload: userCredentials,
});

export const userSignInSuccess = (user: any) => ({
	type: loginActionTypes.USER_SIGN_IN_SUCCESS,
	payload: user,
});

export const userSignInFailure = (error: any) => ({
	type: loginActionTypes.USER_SIGN_IN_FAILURE,
	payload: error,
});

export const userSignInResponseResetStart = () => ({
	type: loginActionTypes.USER_SIGN_IN_RESPONSE_CHANGED,
});

export const userSignInResponseChanged = () => ({
	type: loginActionTypes.USER_SIGN_IN_RESPONSE_RESET,
});
/************ USER LOGIN CHECK END *****************/


/************ USER LOGOUT SATRT *****************/
export const userSignInLogOutStart = () => ({
	type: loginActionTypes.USER_SIGN_OUT_START,
});

export const userSignInLogOut = () => ({
	type: loginActionTypes.USER_SIGN_OUT,
});
/************ USER LOGOUT SATRT *****************/

/************ USER AUTH CODE CHECK SATRT *****************/
export const userAuthVerifyStart = (userCredentials: any) => ({
	type: loginActionTypes.USER_AUTH_VERIFY_START,
	payload: userCredentials,
});

export const userAuthVerifySuccess = (user: any) => ({
	type: loginActionTypes.USER_AUTH_VERIFY_SUCCESS,
	payload: user,
});

export const userAuthVerifyFailure = (error: any) => ({
	type: loginActionTypes.USER_AUTH_VERIFY_FAILURE,
	payload: error,
});

export const userAuthVerifyResponseResetStart = () => ({
	type: loginActionTypes.USER_AUTH_VERIFY_RESPONSE_RESET,
});

export const userAuthVerifyResponseChanged = () => ({
	type: loginActionTypes.USER_AUTH_VERIFY_RESPONSE_CHANGED,
});
/************ USER AUTH CODE CHECK END *****************/