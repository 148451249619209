import React, { FC, useCallback, useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useFormik } from 'formik';
import moment from 'moment';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
import { randomizeFetch } from '../../../redux/login/login.utils';
import {
	selectCurrentUser,
	selectCurrentDecodedUser,
	getLoginObjDetails
} from '../../../redux/login/login.selector';
import {
	userSignInStart,
	userSignInResponseResetStart
} from '../../../redux/login/login.action';

const Login = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { setUser } = useContext(AuthContext);
	const { darkModeStatus } = useDarkMode();

	const getCurrentUserLoginDetails = useSelector(selectCurrentUser);
	const loginRespsArr = useSelector(selectCurrentDecodedUser);
	const loginData = useSelector(getLoginObjDetails);

	const [isShowError, setShowErrorCode] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleOnClick = useCallback(() => navigate('/'), [navigate]);

	useEffect(() => {
		if (loginRespsArr !== null && loginRespsArr.length > 0) {
			// const { userID } = loginData;
			// const data = {
			// 	userID,
			// 	job: 'Napa',
			// 	jobCode: 'RFR',
			// };
			// console.log("API---1");
			
			// dispatch(fetchApplicantDetailsStart(data));

			// handleOnClick();
		}
	}, [])

	useEffect(() => {
		if (getCurrentUserLoginDetails != null) {
			if (getCurrentUserLoginDetails.statusCode === '01') {
				// const dataFor = randomizeFetch(loginData.rondomObj);
				// const { userID } = loginData;
				// const data = {
				// 	userID,
				// 	job: 'Napa',
				// 	jobCode: 'RFR',
				// };
				// console.log("API---0");
				// dispatch(fetchApplicantDetailsStart(data));
				handleOnClick();
			} else {
				setShowErrorCode(true);
				setIsLoading(false);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon
							icon='Info'
							size='lg'
							className='me-1'
						/>
						<span>Error</span>
					</span>,
					'Please, Check your login credentials', 'danger'
				);
			}
			dispatch(userSignInResponseResetStart());
		}
	}, [getCurrentUserLoginDetails]);


	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
		},
		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string } = {};

			if (!values.loginUsername) {
				errors.loginUsername = 'Required';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Required';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			setIsLoading(true);
			setShowErrorCode(false);
			dispatch(userSignInStart(values));
		},
	});

	return (
		<PageWrapper
			isProtected={false}
			title={'Login'}
			className='bg-success'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}
										aria-label='Facit'>
										<Logo width={200} />
									</Link>
								</div>

								<div>
									<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
									<div className='text-center h4 text-muted mb-5'>Login in to continue!</div>
								</div>

								{ isShowError ?
									<div className='mb-4'>
										<Alert
											color='warning'
											isLight
											icon='Report'
											className='mb-0'>
											Please, Check your Phonenumber and Password
										</Alert>
									</div> : null
								}

								<form className='row g-4'>
										<>
											<div className='col-12'>
												<FormGroup
													id='loginUsername'
													isFloating
													label='Your phonenumber'
												>
													<Input
														autoComplete='username'
														value={formik.values.loginUsername}
														isTouched={formik.touched.loginUsername}
														invalidFeedback={
															formik.errors.loginUsername
														}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='loginPassword'
													isFloating
													label='Password'
												>
													<Input
														type='password'
														autoComplete='current-password'
														value={formik.values.loginPassword}
														isTouched={formik.touched.loginPassword}
														invalidFeedback={
															formik.errors.loginPassword
														}
														validFeedback='Looks good!'
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<Button
													color='dark'
													className='w-100 py-3'
													onClick={formik.handleSubmit}>
													{isLoading && (
														<Spinner isSmall inButton isGrow />
													)}
													Let's Start
												</Button>
											</div>
										</>
								</form>
							</CardBody>
						</Card>
						{/* <div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Terms of use
							</a>
						</div> */}
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
export default Login;
