import { takeLatest, put, all, call } from 'redux-saga/effects';

import loginActionTypes from './login.types';
import { userSignIn, authUserVerification } from './login.services';
import {
	userSignInSuccess,
	userSignInFailure,
	userSignInResponseChanged,
	userSignInLogOut,
	userAuthVerifySuccess,
	userAuthVerifyFailure,
	userAuthVerifyResponseChanged,
} from './login.action';
import { encodeJson } from '../_common/enode-decode';

/************ USER LOGIN CHECK SATRT *****************/
type AnyAction = {type: JSON, [key: string]: any}
export function* singInWithPhoneNumber({ payload } : AnyAction) {
	const key: any = 'UserKeyLogin';
	try {
		const { loginUsername, loginPassword } = payload;
		
		const jsonData: any = {
			UserName: loginUsername,
			UserPassword: loginPassword
		};
		const loginData = encodeJson(jsonData, key);
        // @ts-ignore
		const responseData = yield call(userSignIn, loginData);
		if (responseData.data.statusCode === '100') {
			yield put(userSignInFailure(responseData.data));
		} else {
			yield put(userSignInSuccess(responseData.data));
		}
	} catch (error) {
		yield put(userSignInFailure(error));
	}
}

export function* makeUserLoginReset() {
	yield put(userSignInResponseChanged());
}

export function* onPhoneSignInReset() {
	yield takeLatest(loginActionTypes.USER_SIGN_IN_RESPONSE_RESET, makeUserLoginReset);
}

export function* onPhoneSignInStart() {
	yield takeLatest(loginActionTypes.USER_SIGN_IN_START, singInWithPhoneNumber);
}
/************ USER LOGIN CHECK END *****************/


/************ USER LOGIN AUTH VERIFY CHECK SATRT *****************/
export function* verifyGoogleAuth({ payload } : AnyAction) {
	const key: any = 'UserAuthCheck';
	try {
		const { secrateAuth, authKeyNumber, userID, staffID } = payload;
		
		const jsonData: any = {
			secrateAuth,
			authKeyNumber,
			userID,
			staffID,
		};
		const authVerifyData = encodeJson(jsonData, key);
        // @ts-ignore
		const responseData = yield call(authUserVerification, authVerifyData);
		if (responseData.data.statusCode === '100') {
			yield put(userAuthVerifyFailure(responseData.data));
		} else {
			yield put(userAuthVerifySuccess(responseData.data));
		}
	} catch (error) {
		yield put(userAuthVerifyFailure(error));
	}
}

export function* googleAuthVerifyReset() {
	yield put(userAuthVerifyResponseChanged());
}

export function* onAuthVerifyReset() {
	yield takeLatest(loginActionTypes.USER_AUTH_VERIFY_RESPONSE_RESET, googleAuthVerifyReset);
}

export function* onAuthVerifyStart() {
	yield takeLatest(loginActionTypes.USER_AUTH_VERIFY_START, verifyGoogleAuth);
}
/************ USER LOGIN AUTH VERIFY CHECK END *****************/

/***********LOGOUT ****************/
export function* makeUserSignInLogout() {
	yield put(userSignInLogOut());
}

export function* userSignInLogout() {
	yield takeLatest(loginActionTypes.USER_SIGN_OUT_START, makeUserSignInLogout);
}


export function* loginSaga() {
	yield all([
		call(onPhoneSignInStart),
		call(userSignInLogout),
		call(onPhoneSignInReset),
		call(onAuthVerifyReset),
		call(onAuthVerifyStart)
	]);
}
