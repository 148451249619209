// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-vars */
const operationActionTypes = {
	// NAPA FETCH LIST
	FETCH_APPLICANT_DETAILS_START: 'fetch_applicant_details_start',
	FETCH_APPLICANT_DETAILS_SUCCESS: 'fetch_applicant_details_success',
	FETCH_APPLICANT_DETAILS_FAILURE: 'fetch_applicant_details_failure',
	FETCH_NAPA_DETAILS_RESET_RESET_START: 'fetch_applicant_details_reset_start',
	FETCH_NAPA_DETAILS_RESET_RESPONSE_CHANGED: 'fetch_applicant_details_response_changed',

	// PROCEESS NAPA APPLICANTS SHORT
	PROCESS_APPLICANTS_PROFILE_START: 'process_napa_appicants_short_start',
	PROCESS_APPLICANTS_PROFILE_SUCCESS: 'process_napa_appicants_short_success',
	PROCESS_APPLICANTS_PROFILE_FAILURE: 'process_napa_appicants_short_failure',
	PROCESS_APPLICANTS_PROFILE_RESET: 'process_napa_appicants_short_reset',
	PROCESS_APPLICANTS_PROFILE_RESPONSE_CHANGED: 'process_napa_appicants_short_response_changed',

	// HIGHMARK CHECK DETAILS
	FETCH_HIGHMARK_DETAILS_START: 'highmark_check_details_start',
	FETCH_HIGHMARK_DETAILS_SUCCESS: 'highmark_check_details_success',
	FETCH_HIGHMARK_DETAILS_FAILURE: 'highmark_check_details_failure',
	FETCH_HIGHMARK_DETAILS_RESET_START: 'highmark_check_details_reset',
	FETCH_HIGHMARK_DETAILS_RESPONSE_CHANGED: 'highmark_check_details_response_changed',

	SALARIED_GIN_SUGGESION_CHANGED: 'salaried_gin_suggesion_changed',

	ADD_NAPA_FOLLOWUP_START: 'add_napa_followup_start',
	ADD_NAPA_FOLLOWUP_SUCCESS: 'add_napa_followup_success',
	ADD_NAPA_FOLLOWUP_FAILURE: 'add_napa_followup_failure',
	ADD_NAPA_FOLLOWUP_RESET_START: 'add_napa_followup_reset_start',
	ADD_NAPA_FELLOWUP_RESPONSE_CHANGED: 'add_napa_followup_response_changed',

	FETCH_NAPA_FOLLOWUP_START: 'fetch_napa_followup_start',
	FETCH_NAPA_FOLLOWUP_SUCCESS: 'fetch_napa_followup_success',
	FETCH_NAPA_FOLLOWUP_FAILURE: 'fetch_napa_followup_failure',
	FETCH_NAPA_FOLLOWUP_RESET_START: 'fetch_napa_followup_reset_start',
	FETCH_NAPA_FELLOWUP_RESPONSE_CHANGED: 'fetch_napa_followup_response_changed',
};

export default operationActionTypes;
