// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/default-param-last */
import collectionActionTypes from './collection.types';
import { docodeNapaResponse, docodeResponse } from '../_common/common.utils';

const INITIAL_STATE: any = {
	addCollectionFollowUpResponse: null,
	fetchCollectionFollowUpResponse: null,
	fetchCollectionFollowUpArr: [],
	loanDetailsFetchResponse: null,
	loanDetailsFetchArr: [],
	loanInfoFetchResponse: null,
	loanInfoFetchArr: [],
};

const collectionReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case collectionActionTypes.ADD_COLLECTION_FOLLOWUP_SUCCESS:
			return {
				...state,
				addCollectionFollowUpResponse: action.payload,
			};

		case collectionActionTypes.ADD_COLLECTION_FOLLOWUP_FAILURE:
			return {
				...state,
				addCollectionFollowUpResponse: action.payload,
			};

		case collectionActionTypes.ADD_COLLECTION_FELLOWUP_RESPONSE_CHANGED:
			return {
				...state,
				addCollectionFollowUpResponse: null,
			};

		case collectionActionTypes.FETCH_COLLECTION_FOLLOWUP_START:
			return {
				...state,
				fetchCollectionFollowUpResponse: null,
				fetchCollectionFollowUpArr: [],
			};

		case collectionActionTypes.FETCH_COLLECTION_FOLLOWUP_SUCCESS:
			return {
				...state,
				fetchCollectionFollowUpResponse: action.payload,
				fetchCollectionFollowUpArr: docodeResponse(action.payload, 'followUpFetch'),
			};

		case collectionActionTypes.FETCH_COLLECTION_FOLLOWUP_FAILURE:
			return {
				...state,
				fetchCollectionFollowUpResponse: action.payload,
			};

		case collectionActionTypes.FETCH_COLLECTION_FELLOWUP_RESPONSE_CHANGED:
			return {
				...state,
				fetchCollectionFollowUpResponse: null,
			};

		/** ************ LOAN DETAILS FETCH LIST ******************* */

		case collectionActionTypes.LOAN_DETAILS_FETCH_SUCCESS:
			return {
				...state,
				loanDetailsFetchResponse: action.payload,
				loanDetailsFetchArr: docodeNapaResponse(action.payload, 'loanDetails'),
			};

		case collectionActionTypes.LOAN_DETAILS_FETCH_FAILURE:
			return {
				...state,
				loanDetailsFetchResponse: action.payload,
			};

		case collectionActionTypes.LOAN_DETAILS_FETCH_RESPONSE_CHANGED:
			return {
				...state,
				loanDetailsFetchResponse: null,
			};

		/** ************ LOAN INFO FETCH LIST ******************* */

		case collectionActionTypes.LOAN_INFO_FETCH_SUCCESS:
			return {
				...state,
				loanInfoFetchResponse: action.payload,
				loanInfoFetchArr: docodeNapaResponse(action.payload, 'loanInfoDetails'),
			};

		case collectionActionTypes.LOAN_INFO_FETCH_FAILURE:
			return {
				...state,
				loanInfoFetchResponse: action.payload,
			};

		case collectionActionTypes.LOAN_INFO_FETCH_RESPONSE_CHANGED:
			return {
				...state,
				loanInfoFetchResponse: null,
			};

		default:
			return state;
	}
};

export default collectionReducer;
