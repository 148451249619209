
import { createSelector } from 'reselect';

const selectUser = (state: any) => state.login;

export const selectCurrentUser = createSelector([selectUser], (login) => login.loginResponse);

// UPDATE LOGIN DATE TIME
export const selectAuthCheck = createSelector(
	[selectUser], (login) => login.authCheckDone,
);

// LOGIN AUTH CHECK 
export const selectAuthVerifyResponse = createSelector(
	[selectUser], (login) => login.userAuthVerifyResponse,
);

export const selectCurrentDecodedUser = createSelector(
	[selectUser],
	(login) => login.loginDeocodedResponse,
);

export const getLoginObjDetails = createSelector([selectUser], (login) => {
	const { loginDeocodedResponse, currencySymbols } = login;
	let loginObj: any = {
		userID: '',
		staffID: '',
		userName: '',
		userPhoto: '',
		userPhoneNumber: '',
		secrateAuth: '',
		companyID: '',
		rondomObj: '',
		lastloginTime: '',
		currency: '',
	};
	if (loginDeocodedResponse !== null && loginDeocodedResponse !== undefined && loginDeocodedResponse.length > 0) {
		const loginData = loginDeocodedResponse[0];
		loginObj = {
			userID: loginData.userID,
			staffID: loginData.staffID,
			userName: loginData.userName,
			userPhoto: loginData.userPhoto,
			userPhoneNumber: loginData.userPhoneNumber,
			companyID: loginData.companyID,
			secrateAuth: loginData.secrateAuth,
			rondomObj: loginData.mode,
			lastloginTime: loginData.lastloginTime,
			currency: currencySymbols,
		};
	}
	return loginObj;
});